<template>
    <div class="page-gray">
        <div class="uploadImg">
            <van-uploader
                v-model="form.fileList"
                multiple
                :max-count="4"
                :after-read="afterRead"
                :before-read="beforeRead"
                upload-icon="plus"
            />
        </div>
        <div class="form">
            <div class="form-item block">
                <label class="required">商品名称</label>
                <van-field v-model="form.name" placeholder="请输入商品名称" />
            </div>

            <div class="form-item block">
                <label class="">商品简称</label>
                <van-field v-model="form.shortName" placeholder="请输入商品简称" />
            </div>

            <div class="form-item">
                <label class="required">价格</label>
                <van-field  v-model="form.price" label="¥" label-width="10" placeholder="请输入价格" type="number" />
            </div>

            <div class="form-item">
                <label class="required">性别</label>
                <picker-item
                    v-model="form.sex"
                    :list="[{text: '女款', value: '1'},{text: '男款', value: '2'},{text: '男女款', value: '3'}]"
                    placeholder="请选择性别"
                />
            </div>

             <div class="form-item">
                <label class="required">排序</label>
                <van-field  v-model="form.sort" placeholder="请输入排序" type="digit" />
            </div>

             <div class="form-item">
                <label class="required">销售方式</label>
                <picker-item
                    v-model="form.salesWay"
                    :list="[{text: '限时预定', value: '1'},{text: '随时购买', value: '2'}]"
                    placeholder="请选择销售方式"
                />
            </div>

            <div class="form-item">
                <label class="required">预售</label>
                <picker-item
                    v-model="form.isYs"
                    :list="[{text: '是', value: '1'},{text: '否', value: '0'}]"
                    align="right"
                    placeholder="请选择"
                />
            </div>

            <div class="form-item">
                <label class="required">展示倒计时</label>
                <picker-item
                    v-model="form.countDown"
                    align="right"
                    :list="[{text: '是', value: '1'},{text: '否', value: '0'}]"
                    placeholder="请选择"
                />
            </div>


            <div class="form-item">
                <label class="required">日期</label>
                <timer-item v-model="form.time"/>
            </div>

            <div class="form-item swtich">
                <label class="required">商品规格</label>
                <div class="swtichGroup">
                    <div>
                        <span>统一规格</span>
                        <van-switch v-model="form.isUniform"  size="18" active-color="#FE5841" />
                    </div>
                     <div>
                        <span  @click="form.isMultiform ? $router.push('/shelves/setMultiform') : void 0" style="color: #4386F4;">多规格</span>
                        <van-switch v-model="form.isMultiform" size="18" active-color="#FE5841" />
                    </div>
                </div>
            </div>

            <div class="form-item">
                <label class="required">配送方式</label>
                <picker-item
                    v-model="form.sendWay"
                    @select="onSendWaySelect"
                    :list="[{text: '快递到家', value: '1'},{text: '配送到校', value: '2'}]"
                    placeholder="请选择配送方式"
                />
            </div>

            <div class="form-item swtich">
                <label class="required">运费设置</label>
                <div class="swtichGroup">
                    <div>
                        <span>免邮费</span>
                        <van-switch v-model="form.exemptPostage"  size="18" active-color="#FE5841" />
                    </div>
                     <div>
                        <span>使用邮费模版</span>
                        <van-switch v-model="form.postageTemplate" size="18" active-color="#FE5841" />
                    </div>

                    <div class="box" v-if="form.postageTemplate">邮费模版1</div>
                </div>
            </div>


            <div class="form-item">
                <label class="required">预计配送时间</label>
                <van-field v-model="form.expectTime" placeholder="请输入预计配送时间"/>
            </div>

            <div class="form-item">
                <label>链接进销存同步库存</label>
                <picker-item
                    v-model="form.syncOfInventory"
                    align="right"
                    @select="onSyncOfInventoryChange"
                    :list="[{text: '是', value: '1'},{text: '否', value: '0'}]"
                    placeholder="请选择"
                />
            </div>

            <div class="form-item">
                <label>库存标识</label>
                <picker-item
                    v-model="form.inventoryTag"
                    :disabled="form.syncOfInventory == '0'"
                    :list="[{text: '预售库', value: '1'},{text: '实体库', value: '2'}]"
                    placeholder="请选择"
                />
            </div>

            <div class="form-item">
                <label class="required">学校</label>
                <picker-item
                    v-model="form.school"
                    :list="[
                        {text: '超过屏幕60%高度将会出现滚动条', value: '1'},{text: '往下的内容需要滑动显示', value: '2'},{text: '长沙高新区明德麓谷学校', value: '3'},{text: '长沙岳麓区博雅小学', value: '4'},{text: '长沙高新区明德麓谷学校', value: '5'},{text: '长沙岳麓区博雅小学', value: '6'},{text: '长沙高新区明德麓谷学校', value: '7'},{text: '长沙岳麓区博雅小学', value: '8'}
                    ]"
                    placeholder="请选择"
                    @select="getGradeList"
                />
            </div>

            <div class="form-item block" v-if="gradeList.length > 0">
                <label class="required">年级(多选) <span><i @click="form.grade = gradeList.map(e => e.value)" v-if="form.grade.length !== gradeList.length">全选</i> <i v-if="form.grade.length === gradeList.length" @click="form.grade = []">取消全选</i></span></label>
                <van-checkbox-group v-model="form.grade">
                    <van-checkbox
                        class="checkBox"
                        v-for="item in gradeList"
                        icon-size="13"
                        checked-color="#f00"
                        shape="square"
                        :name="item.value">
                            {{item.text}}
                    </van-checkbox>
                </van-checkbox-group>
            </div>


            <div class="form-item block">
                <label class="">购买提示</label>
                <van-field
                    v-model="form.buyTips"
                    rows="3"
                    :autosize="true"
                    type="textarea"
                    :maxlength="200"
                    placeholder="请输入"
                />
            </div>


            <div class="form-item block">
                <label class="">尺码提示</label>
                <van-field
                    v-model="form.sizeTips"
                    rows="3"
                    :autosize="true"
                    type="textarea"
                    :maxlength="200"
                    placeholder="请输入"
                />
            </div>

             <div class="form-item block tinymce" >
                <label class="">必看提示</label>
                <tinymce v-model="form.mustTips"/>
                <!-- <textarea v-model="form.mustTips" id="mytextarea" style="width: 100%"/> -->
            </div>
        </div>


        <div class="button">
            <van-button type="primary" @click="submit" block>下一步</van-button>
        </div>
    </div>
</template>

<script>
import Tinymce from '../../components/tinymce.vue';
import PickerItem from './eidtComponents/pickerItem.vue'
import TimerItem from './eidtComponents/timerItem.vue';
import verify from '@/lib/verify';
import rules from './editRules'
export default {
    name: "ShelvesEdit",
    data() {
        return {
            form: {
                fileList: [{
                    url: 'https://gw.alicdn.com/bao/uploaded/i1/278383096/O1CN01zf3wmw1Yk0jjNMFio_!!278383096.jpg_300x300q90.jpg_.webp'
                }],
                name: '长沙市雨花区砂子塘万境第二小学夏季女款', //商品名称
                shortName: '', // 商品简称
                price: '100', // 价格
                sex: '1', // 性别
                sort: '10', //排序
                salesWay: '1', // 销售方式
                isYs: '1', // 是否预售
                countDown: '1', // 是否倒计时
                time: ['2022-01-01 01:01', '2022-05-05 23:59'], // 开始时间 结束时间

                isUniform: true, // 是否统一规格
                isMultiform: false, // 是否多规格

                sendWay: '1', //配送方式

                exemptPostage: true, //包邮
                postageTemplate: false, //邮费模版
                expectTime: '2022年8月30日左右', //预计时间
                syncOfInventory: '1', // 链接进销存同步库存
                inventoryTag: '1', // 库存标识
                school: '1', // 学校
                grade: ['1'], // 年级

                buyTips: '家长您好：校服按照孩子实际身高订购即可，切勿定大尺码。(例如：小孩身高120cm，则订购120码正合身，订购125码偏宽松），以免引起不必要的退换麻烦。', //购买提示
                sizeTips: '家长您好：校服按照孩子实际身高订购即可，切勿定大尺码。(例如：小孩身高120cm，则订购120码正合身，订购125码偏宽松），以免引起不必要的退换麻烦。' , //尺码提示
                mustTips: '<p style="color: #f00;font-size: 10pt;">温馨提示：限时预定，3月15号左右配送至校。</p>', // 必看提示
            },
            allGradeCheck: false,
            gradeList: []
        }
    },
    components: {
        PickerItem,
        TimerItem,
        Tinymce
    },
    watch: {
        'form.isUniform'() {
            if (this.form.isUniform) {
                this.form.isMultiform = false
            }
        },

        'form.isMultiform'() {
            if (this.form.isMultiform) {
                this.form.isUniform = false
            }
        },

        'form.exemptPostage'() {
            if (this.form.exemptPostage) {
                this.form.postageTemplate = false
            }
        },

        'form.postageTemplate'() {
            if (this.form.postageTemplate) {
                this.form.exemptPostage = false
            }
        },
        
    },

    methods: {
        beforeRead(file) {
            if (file.type !== 'image/jpeg') {
                this.$toast('请上传 jpg 格式图片');
                return false;
            }
            return true;
        },
        // 组件上传
        afterRead(file) {
            file.status = 'uploading';
            file.message = '上传中...';
            console.log(file.content); // 给到的是一个base64字符串

            //上传请求
            setTimeout(() => {
                // file.status = 'failed';
                // file.message = '上传失败';
                file.status = 'success';
                file.message = '上传成功';
                // 上传完了之后可以把后台返回的链接给到content
                // file.content = res.data.url
            }, 1000);
        },
   

        onSendWaySelect(item) {
            if (item.value == 2) {
                this.form.exemptPostage = true
            }

            if (item.value == 1) {
                this.form.postageTemplate = true
            }
        },

        onSyncOfInventoryChange(item ) {
            if (item.value == '0') {
               this.form.inventoryTag = '1'
            }
        },

        getGradeList() {
            this.$toast.loading({ duration: 0, forbidClick: true });
            setTimeout(() => {
                this.$toast.loading({ duration: 1})
                this.gradeList = [{
                    text: '一年级',
                    value: '1'
                },{
                    text: '二年级',
                    value: '2'
                },{
                    text: '三年级',
                    value: '3'
                },{
                    text: '四年级',
                    value: '4'
                },{
                    text: '五年级',
                    value: '5'
                },{
                    text: '六年级',
                    value: '6'
                }]
            }, 1000)
        },

        submit() {
           let result = verify(this.form, rules)
           console.log(result)
           if (!result.isOk) {
                this.$toast(result.resultMsg);
                return;
           }
            localStorage.setItem('ShelvesDetail', JSON.stringify(this.form))
            this.$router.push('/shelves/detail')
        },
    },
    mounted() {
      this.getGradeList()
    }
}

</script>
<style lang="less" scoped>
.page-gray {
    background-color: #fff;
}

::v-deep .van-field__control, ::v-deep .van-field__label {
    font-size: 14px;
    color: #212121;
    margin-right: 0;
}

.form {
    .form-item {
        background: #F7F8F9;
        border-radius: 5px;
        display: flex;
        align-items: center;
        height: 51px;
        padding: 0 17px;
        margin: 10px;
        &.tinymce {
            padding: 0 0 5px !important;
            label {
                padding: 7px 17px 2px;
            }
        }
        &.block {
            flex-direction: column;
            align-items: flex-start;
            padding: 9px 17px;
            height:  auto;

            label {
                width: 100%;
                display: flex;
                justify-content: space-between;
                
                i {
                    margin-left: 10px;
                }
            }
        }

        &.swtich {
            align-items: flex-start;
            height: auto;
            label {
                margin-top: 18px;
            }
        }
        label {
            min-width: 105px;

            font-size: 14px;
            font-weight: 500;
            color: #212121;
            position: relative;
            display: block;
            &.required::after {
                content: '*';
                font-size: 14px;
                position: absolute;
                left: -7px;
                top: 0;
                color: #f00;
            }

            i {
                font-style: normal;

                font-size: 12px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #212121;
            }
        }

        .van-cell {
            background-color: transparent;
            padding: 5px 0;
            flex: 1;
        }

        .swtichGroup {
            padding: 18px 0 8px;
            > div {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                span {
                    display: block;
                    width: 105px;
                    font-size: 14px;
                    color: #333;
                }
            }

            .box {
                width: 170px;
                background: #EAEAEA;
                border-radius: 4px;
                margin-top: 15px;
                line-height: 1;
                font-size: 12px;
                font-weight: 400;
                color: #333333;
                padding: 7px 11px;
            }
        }
    }
}

.button {
    padding: 20px 18px;
}
.van-checkbox-group {
    margin-top: 7px;
}
.checkBox {
    width: 29%;
    display: inline-flex;
    margin: 4px 0;
}
::v-deep .van-uploader__preview, ::v-deep .van-uploader__upload {
    height: 64px;
    width: 77px;
    margin-bottom: 0;
    margin-top: 10px;
    margin-right: 0;
    margin-left: 10px;
    border-radius: 5px;
}

::v-deep .van-uploader__upload {
    border: 1px dashed #DDDDDD;
    background-color: #fff;

}
::v-deep .van-image {
    height: 64px;
    width: 77px;
    border-radius: 5px;
}
</style>
