
/**
 * long description for the file
 *
 * @summary short description for the file
 * @author LinRenChen
 *
 * Created at     : 2019-07-25 23:19:35 
 * Last modified  : 2022-07-03 23:01:30
 */

/**
 *  @param { Object } data 对应的数据
 * let data = { name: '', age: '', area: '', phone: ''}
 *  @param { Object} rule 对应的规则 ， 规则里面必须包含 msg字段
 *  
 *
let rule = {
    name: [{
        required: true,
        message: '请输入名字'
    }],
    nameMsg: '',
    age: [{
        required: true, //是否必填， 注意 0 是允许输入的
        message: '请输入年龄'
    }, {
        //判断大小的时候， 该值必须是数字， 否则直接认为是错误的
        min: 0, //最小值
        max: 99, //当大值
        message: '请输入0 - 99区间的年龄' 
    }],

    ageMsg: '',
    area: [{
        required: true,
        message: '请选择手机所属区号'
    }],

    areaMsg: '',
    phone: [{
        required: 'area', //这样写代表这个phone 依赖 area，只有area的规则通过了之后才会校验 phone
        message: '请输入手机号'
    }, {
        reg: /[0-9]/,
        message: '手机号码格式错误'
    }],

    phoneMsg: ''
}
 *
 **/


function verify(data, rules) {
    let isOk = true;
    let resultMsg = '';
    for (let i in rules) {
        if (!isOk) { break; }
        let _ = rules[i];
        if (getType(_) !== 'array') {
            continue;
        }
        let _val = data[i];
        if (!_) {
            continue;
        }

        if (_.length > 0) {
            for (let k = 0, len = _.length; k < len; k++) {
              
                let e = _[k];
                //验证是否必填
                if (e.required) {
                    if (isBool(e.required)) {
                        if (e.type === 'Array') {
                            if (_val.length === 0) {
                                resultMsg = e.message;
                                isOk = false;
                                break;
                            }
                        } else {
                            if (_val === '' || _val === null || _val === undefined) {
                                resultMsg = e.message;
                                isOk = false;
                                
                                break;
                            } else {
                                resultMsg = '';
                            }
                        }
                      
                    } else if (isString(e.required)) {
                        if (data[i] !== '' && data[i] !== null && data[i] !== undefined) {
                            if (_val === '' || _val === null || _val === undefined) {
                                resultMsg = e.message;
                                isOk = false;
                                
                                break;
                            }
                        }
                    }
                }


                //验证大小值
                if (e.min || e.max) {
                    if (!isNum(_val)) {
                        resultMsg = e.message;
                        isOk = false;
                        
                        break;
                    }
                    let num = Number(_val);
                    if (e.min && num < e.min) {
                        resultMsg = e.message;
                        isOk = false;
                        
                        break;
                    } 

                    if (e.max && num > e.max) {
                        resultMsg = e.message;
                        isOk = false;
                        
                        break;
                    } 

                }


                //验证正则
                if (e.reg) {
                    let r = e.reg.test(_val)
                    if (e.opposite) {
                        if (r) {
                            resultMsg = e.message;
                            isOk = false;
                            break;
                        }
                    } else {
                        if (!r) {
                            resultMsg = e.message;
                            isOk = false;
                            break;
                        }
                    }
                    
                }
            }
        }
    }


    return { isOk, resultMsg };
}

function getType(val) {
    const type = {
        "[object String]": 'string',
        "[object Array]": 'array',
        "[object Object]": 'object',
        "[object Null]": 'null',
        "[object Number]": 'number',
        "[object Undefined]": 'undefined',
        "[object Boolean]": 'boolean',
        "[object Function]": 'function'
    }
    const resType = Object.prototype.toString.call(val)
    return type[resType]
}
function isNum(val) {
    let num = Number(val);
    if (isNaN(num)) { return false; }
    return true;
}

function isBool(val) {
    return getType(val) === 'boolean';
}

function isString(val) {
    return getType(val) === 'string';
}


export default verify;