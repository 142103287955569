let reg = /[`~!@#$^\-&*()=|{}':;',\\[\].<>/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
let createRequiredRule = (name, type = 'input', dataType) => {
    return {
        required: true,
        message: `${type === 'input' ? '请输入' : '请选择'}${name}`,
        type: dataType
    }
}
export default {
    name: [
        createRequiredRule('名称'),
        {
            reg, message: '请输入正确的名称', opposite: true
        }
    ],
    price:[ createRequiredRule('价格')],
    sex:[ createRequiredRule('性别', 'select')],
    sort: [createRequiredRule('排序')],
    salesWay: [createRequiredRule('销售方式', 'select')],
    time: [createRequiredRule('时间', 'select', 'Array')],

    sendWay: [createRequiredRule('配送方式', 'select')],
    expectTime:[ createRequiredRule('预计配送时间')],
    school: [createRequiredRule('学校', 'select')],
    grade: [createRequiredRule('年级', 'select', 'Array')],
}