<template>
    <div class="TimerItem">
        <div class="box">
            <div class="handle" @click="startTimePopup = true">
                <div class="value" v-if="startTime"> {{startTime}}</div>
                <div class="placehoder" v-else>选择开始时间</div>
                <i />
                <div class="value" v-if="endTime"> {{endTime}}</div>
                <div class="placehoder" v-else>选择结束时间</div>
            </div>
            <van-icon name="arrow-down" :size="px2rem(12)" color="#999" />
        </div>
        <van-popup v-model="startTimePopup" round position="bottom">
            <van-datetime-picker
                type="datetime"
                title="选择开始时间"
                :max-date="maxDate"
                :formatter="formatter"
                @confirm="onStartConfirm"
            />
        </van-popup>

        <van-popup v-model="endTimePopup" round position="bottom">
            <van-datetime-picker
                type="datetime"
                title="选择结束时间"
                :min-date="startTimeStack"
                :max-date="maxDate"
                :formatter="formatter"
                @confirm="onEndConfirm"
            />
        </van-popup>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: "TimerItem",
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: {
            default: () => [],
            type: Array
        }
    },
    data() {
        return {
            startTimePopup: false,
            endTimePopup: false,
            monthPopup: false,
            maxDate: new Date(),
            startTimeStack: '',

            startTime: '',
            endTime: '',
        }
    },

    watch: {
        value: {
            immediate: true,
            handler() {
                if (this.value.length > 0) {
                    this.startTime = this.value[0]
                    this.endTime = this.value[1]
                }
            }
        }
    },



    methods: {
        formatter(type, val) {
            if (type === 'year') {
                return `${val}年`;
            } else if (type === 'month') {
                return `${val}月`;
            }else if (type === 'day') {
                return `${val}日`;
            }else if (type === 'hour') {
                return `${val}时`;
            }else if (type === 'minute') {
                return `${val}分`;
            }
            return val;
        },

       

        onStartConfirm(val) {
            this.startTimeStack = val;
            this.startTimePopup = false;
            this.endTimePopup = true
        },
        onEndConfirm(val) {
            let date = new Date(this.startTimeStack)
            this.startTime = moment(date).format('YYYY-MM-DD HH:mm')

            let endDate = new Date(val);
            this.endTime = moment(endDate).format('YYYY-MM-DD HH:mm')
            this.endTimePopup = false


            this.$emit('change', [this.startTime, this.endTime])
            this.$emit('select', [this.startTime, this.endTime]);
            this.show = false

        }
        
    }
}

</script>
<style lang="less" scoped>
.TimerItem {
    flex: 1;
}

.box {
    display: flex;
    align-items: center;
    justify-content: space-between;
   
}
.handle {
    display: flex;
    align-items: center;
    div {
        font-size: 14px;
        &.placehoder {
            color: #999;
        }

        &.value {
            color: #212121;
        }
    }
    


    i {
        width: 5px;
        height: 1px;
        background-color: #999;
        display: inline-block;
        margin: 0 5px;
    }
}
</style>
