
<template>
    <div class="PickerItem">
        <van-field :placeholder="placeholder" :input-align="align" readonly :disabled="disabled" @click="disabled ? void 0 : openActionSheet()" :value="name" right-icon="arrow-down" />
        <!-- <van-popup v-model="show" position="bottom" round>
            <van-picker
                show-toolbar
                :columns="list"
                @confirm="onConfirm"
            />
        </van-popup> -->

        <van-action-sheet v-model="show">
            <div class="list">
                <div v-for="(item, index) in list" @click="selectStack = item.value" class="item" :class="{'van-hairline--bottom': index !== list.length -1, active: selectStack === item.value}">
                    {{item.text}}
                </div>
            </div>
            <div class="button">
                <van-button type="primary" round block color="#D53A26" @click="onConfirm">确定</van-button>
            </div>
        </van-action-sheet>
    </div>
</template>

<script>
export default {
    name: "PickerItem",
    model: {
        prop: 'select',
        event: 'change'
    },
    props: {
        disabled: {
            default: false,
            type: Boolean
        },
        list: {
            default: () => [],
            type: Array
        },

        align: {
            default: 'left',
            type: String,
        },
        select: {
            default: '',
            type: String
        },
        placeholder: {
            default: '请选择',
            type: String
        },
    },
    
    data() {
        return {
            selectStack: '',
            selectId: '',
            show: false
        }
    },

    watch: {
        select: {
            immediate: true,
            handler() {
                this.selectId = this.select
            }
        }
    },

    computed: {
        name() {
            if (this.list.length === 0) {
                return ''
            }
            let item = this.list.find(e => e.value === this.selectId)
            return item ? item.text : ''
        }
    },
    methods: {
        openActionSheet() {
            this.selectStack = this.selectId;
            this.show = true
        },
        onConfirm() {
            this.selectId = this.selectStack
            this.$emit('change', this.selectId)
            this.$emit('select', this.list.find(e => e.value === this.selectStack));
            this.show = false
        },
    }
}

</script>
<style lang="less" scoped>
.van-cell {
    background-color: transparent;
    padding: 5px 0;
}

::v-deep .van-field__control, ::v-deep .van-field__label {
    font-size: 14px;
    color: #212121;
}
::v-deep .van-field__right-icon .van-icon {
    font-size: 12px !important;
}

.PickerItem {
    flex: 1;
}
.list {
    max-height:60vh;
    overflow-y: auto;
}

.item {
    height: 54px;
    line-height: 54px;
    text-align: center;

    font-size: 14px;
    font-weight: 500;
    color: #212121;

    &.active {
        color: #D53A26;
    }
}
.button {
    padding: 3px 10px 23px;
}
</style>
